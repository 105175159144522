import gql from 'graphql-tag'

const valueAddedServicesList = gql`query GetValueAddedServicesList {
    valueAddedService {
      displayText
      blurb
      thumbnailImage {
        urls(first: 1)
      }
      alias {
        alias
      }
    }
  }`

const valueAddedService = gql`query GetValueAddedService($alias: String!) {
    valueAddedService(where: {alias: {alias: $alias}}) {
      displayText
      bodyCopy {
        contentItems {
          ... on ContentBlock {
            displayText
            markdownBody {
              html
            }
            contentBlockTitle
            contentBlockSubtitle
            additionalContent {
              contentItems {
                ... on Image {
                  image {
                    urls
                  }
                }
                ... on ContentBlockFlowpart {
                  displayText
                  contentBlockSubtitle
                  markdownBody {
                    html
                  }
                  image {
                    urls
                  }
                  imagePosition
                }
              }
            }
          }
        }
      }
      bannerImage {
        urls(first: 1)
      }
    }
  }`

  interface valueAddedServiceObj {
    alias: any,
    displayText: string,
    blurb: string,
    thumbnailImage: string,
    bannerImage: string,
    bodyCopy: any
  }
  
  export {
    valueAddedServicesList,
    valueAddedService,
    valueAddedServiceObj
  }