import gql from 'graphql-tag'

const popup = gql`query GetPopup {
  popup {
    active
    content {
      html
    }
    displayText
    textColour
    backgroundColour
  }
}`

interface popupObj {
  active: boolean,
  displayText: string,
  content: {
    html: String
  }
  textColour: string,
  backgroundColour: string,
}

export {
  popup,
  popupObj
}