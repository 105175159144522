import gql from 'graphql-tag'

const promoCount = gql`query GetPromotion {
  promotion(orderBy: {published: DESC}) {
    display
  }
}`

const promotions = gql`query GetPromotion {
  promotion(orderBy: {published: DESC}) {
    display
    start
    end
    displayText
    alias {
      alias
    }
    blurb
    bodyCopy {
      contentItems {
        ... on ContentBlock {
          contentBlockTitle
        }
      }
    }
    thumbnailImage {
      urls(first: 1)
    }
  }
}`

const promotion = gql`query GetPromotion($alias: String!) {
  promotion (where: {alias: {alias: $alias}}) {
    display
    start
    end
    displayText
    bannerImage {
      urls(first: 1)
    }
    bodyCopy {
      contentItems {
        ... on ContentBlock {
          contentBlockTitle
          contentBlockSubtitle
          markdownBody {
            html
          }
          additionalContent {
            contentItems {
              ... on Buttons {
                buttonList {
                  contentItems {
                    ... on LinkMenuItem {
                      linkMenuItem {
                        name
                        url
                      }
                    }
                  }
                }
              }
              ... on Image {
                image {
                  urls(first: 1)
                }
                displayText
                altText
              }
              ... on ContentBlockFlowpart {
                displayText
                contentBlockSubtitle
                markdownBody {
                  html
                }
                image {
                  urls(first: 1)
                }
                imagePosition
              }
            }
          }
        }
      }
    }
  }
}`

interface promotionObj {
  display: boolean,
  start: string,
  end: string,
  displayText: string,
  alias: any,
  blurb: string,
  thumbnailImage: string,
  bannerImage: string,
  bodyCopy: any
}

export {
  promoCount,
  promotions,
  promotion,
  promotionObj
}