import { ref, Ref } from "vue";

export function formatTyreResults (resp: any) {
    const formatObject = Object.values(resp.value).map(function (item: any) {
        if(item.type == "Multiselect" || item.type == "Rank") {
            return {[item.title]: item.options}
        }
    })

    let final: Ref<any> = ref(Object.assign({}, ...formatObject.filter(item => item != undefined)));

    const vehicleResp = resp.value.find((item: any) => item.type == "TyreSearch");
    Object.assign((final.value), {
        manufacturer: vehicleResp.search.manufacturer,
        model: vehicleResp.search.model,
        model_year: vehicleResp.search.modelYear,
        specification: vehicleResp.search.specification,
        tyre_size: vehicleResp.search.size
    });

    return final.value;
}