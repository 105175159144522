import gql from 'graphql-tag'

const wheelBrand = gql`query GetWheelBrand {
  taxonomy(where: {alias: {alias: "wheel-brand"}}) {
    taxonomy {
      contentItems {
        displayText
        contentItemId
        ... on WheelBrand {
          alias {
            alias
          }
        }
      }
    }
  }
}`

const wheelList = gql`query GetWheelList {
  wheel(first: 999) {
    displayText
    thumbnailImage {
      urls(first: 1)
    }
    alias {
      alias
    }
    brand {
      termContentItemIds
      termContentItems {
        ... on WheelBrand {
          alias {
            alias
          }
        }
      }
    }
  }
}`

const wheel = gql`query GetWheel($alias: String!) {
  wheel(where: {alias: {alias: $alias}}) {
    displayText
    blurb
    bannerImage {
      urls(first: 1)
    }
    thumbnailImage {
      urls(first: 1)
    }
    bodyCopy {
      contentItems {
        ... on ContentBlock {
          displayText
          markdownBody {
            html
          }
          contentBlockSubtitle
          contentBlockTitle
          additionalContent {
            contentItems {
              ... on Image {
                image {
                  urls
                }
              }
              ... on ContentBlockFlowpart {
                displayText
                contentBlockSubtitle
                markdownBody {
                  html
                }
                image {
                  urls
                }
                imagePosition
              }
            }
          }
        }
      }
    }
  }
}`

interface wheelBrand {
  alias: any,
  displayText: string,
  blurb: string,
  thumbnailImage: string,
  category?: {
    termContentItemIds: Array<string>,
    termContentItems?: {
      alias?: {
        alias: string
      }
    }
  }
}

interface wheelObj {
  alias: any,
  displayText: string,
  blurb: string,
  thumbnailImage: string,
  bannerImage: string,
  bodyCopy: any,
  category?: {
    termContentItemIds: Array<string>,
    termContentItems?: {
      alias?: {
        alias: string
      }
    }
  }
}

export {
  wheelBrand,
  wheelList,
  wheel,
  wheelObj
}