import { handleError } from "@/utilities";

const xSurface = "qt-hiq";
const VehicleQuickTyreEndpoint = "/WheelSize/";
export interface VehicleManufacturer {
  slug: string,
  name: string
}
export interface VehicleModel {
  slug: string,
  name: string,
  year_ranges: string[]
}
export interface VehicleModelYear {
  slug: string,
  name: string,
  year_ranges: string[]
}
export interface VehicleSpecification {
  slug: string,
  fullName: string
  trim: string,
  generationName: string,
  generation: VehicleSpecificationGeneration
}
export interface VehicleSpecificationGeneration {
  slug: string,
  name: string
}
export interface VehicleTyres {
  slug: string,
  tire_type: string,
  wheels: VehicleTyreDetail[]
}
export interface VehicleTyreDetail {
  front: VehicleTyrePositionDetail,
  rear: VehicleTyrePositionDetail
}
export interface VehicleTyrePositionDetail {
  tire: string,
  tire_full: string
}

function formatUrl(request: string, manufacturer: string | undefined = undefined, vehicleModel: string | undefined = undefined, vehicleYear: string | undefined = undefined, vehicleId: string | undefined = undefined) {
  const url = new URL(process.env.VUE_APP_QUICKTYRE_API + VehicleQuickTyreEndpoint + request);

  if (manufacturer) {
    const params = new URLSearchParams();
    params.set('make', manufacturer);

    if (vehicleModel){
      params.set('model', vehicleModel);
    }
    if (vehicleYear){
      params.set('year', vehicleYear);
    }
    if (vehicleId){
      params.set('id', vehicleId);
    }
    
    url.search = params.toString();
  }

  return url;
}

// VehicleManufacturers
export function getVehicleManufacturers(): Promise<VehicleManufacturer[]> {
  return new Promise((resolve, reject) => {
    const url = formatUrl("GetMakes");

    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.setRequestHeader("x-surface", xSurface)
    xhr.onload = () => {
      const res = JSON.parse(xhr.responseText);
      if ([200, 201, 204].includes(xhr.status)) {
        resolve(res.resultData.data)
      }
      else {
        handleError(res);
        reject(res);
      }
    };
    xhr.onerror = () => { handleError(xhr.statusText); reject(xhr.statusText); };
    xhr.send();
  });
}

// VehicleModels
export function getVehicleModels(vehicleManufacturer : string): Promise<VehicleModel[]> {
  return new Promise((resolve, reject) => {
    const url = formatUrl("GetModels", vehicleManufacturer);

    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.setRequestHeader("x-surface", xSurface)
    xhr.onload = () => {
      const res = JSON.parse(xhr.responseText);
      if ([200, 201, 204].includes(xhr.status)) {
        resolve(res.resultData.data)
      }
      else {
        handleError(res);
        reject(res);
      }
    };
    xhr.onerror = () => {
      handleError(xhr.statusText);
      reject(xhr.statusText);
    };
    xhr.send();
  });
}

// VehicleModelYears
export function getVehicleModelYears(vehicleManufacturer : string, vehicleModel : string): Promise<VehicleModelYear[]> {
  return new Promise((resolve, reject) => {
    const url = formatUrl("GetYears", vehicleManufacturer, vehicleModel);

    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.setRequestHeader("x-surface", xSurface)
    xhr.onload = () => {
      const res = JSON.parse(xhr.responseText);
      if ([200, 201, 204].includes(xhr.status)) {
        resolve(res.resultData.data)
      }
      else {
        handleError(res);
        reject(res);
      }
    };
    xhr.onerror = () => {
      handleError(xhr.statusText);
      reject(xhr.statusText);
    };
    xhr.send();
  });
}

// VehicleSpecifications
export function getVehicleSpecifications(vehicleManufacturer : string, vehicleModel : string, vehicleModelYear : string): Promise<VehicleSpecification[]> {
  return new Promise((resolve, reject) => {
    const url = formatUrl("GetVariants", vehicleManufacturer, vehicleModel, vehicleModelYear);

    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.setRequestHeader("x-surface", xSurface)
    xhr.onload = () => {
      const res = JSON.parse(xhr.responseText);
      if ([200, 201, 204].includes(xhr.status)) {
        resolve(res.resultData.data)
      }
      else {
        handleError(res);
        reject(res);
      }
    };
    xhr.onerror = () => {
      handleError(xhr.statusText);
      reject(xhr.statusText);
    };
    xhr.send();
  });
}

// VehicleTyre
export function getVehicleTyre(vehicleManufacturer : string | undefined, vehicleModel : string | undefined, vehicleModelYear : string | undefined, vehicleId : string | undefined): Promise<VehicleTyres[]> {
  return new Promise((resolve, reject) => {
    if (!vehicleManufacturer || !vehicleModel || !vehicleModelYear || !vehicleId) {
      resolve([]);
    }
    const url = formatUrl("GetTyreDetails", vehicleManufacturer, vehicleModel, vehicleModelYear, vehicleId);

    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.setRequestHeader("x-surface", xSurface)
    xhr.onload = () => {
      const res = JSON.parse(xhr.responseText);
      if ([200, 201, 204].includes(xhr.status)) {
        resolve(res.resultData.data)
      }
      else {
        handleError(res);
        reject(res);
      }
    };
    xhr.onerror = () => {
      handleError(xhr.statusText);
      reject(xhr.statusText);
    };
    xhr.send();
  });
}

export function clearVehicleCache(): Promise<any> {
  return new Promise((resolve, reject) => {
    const url = formatUrl("ClearCache");
    const params = new URLSearchParams();
    params.set('key', 'clearme');
    url.search = params.toString();

    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.setRequestHeader("x-surface", xSurface)
    xhr.onload = () => {
      if (xhr.responseText == "\"Cleared\"") {
        console.log("%cVehicle data cleared", "color: green")
      }
      else {
        handleError(xhr.responseText);
        reject(xhr.responseText);
      }
    };
    xhr.onerror = () => {
      handleError(xhr.responseText);
      reject(xhr.responseText);
    };
    xhr.send();
  });
}