import axios from "axios";
import * as HIQAPI from "@/api-client";
export const axiosInstance = axios.create();

const globalConf = new HIQAPI.Configuration({
  basePath: process.env.VUE_APP_ROOT_API,
});

const ContactRequests: HIQAPI.ContactRequestsApi = new HIQAPI.ContactRequestsApi(globalConf);
const QuoteRequests: HIQAPI.QuoteRequestsApi = new HIQAPI.QuoteRequestsApi(globalConf);
const FranchiseRequests: HIQAPI.FranchiseApplicationsApi = new HIQAPI.FranchiseApplicationsApi(globalConf);
const FleetContactRequests: HIQAPI.FleetContactRequestsApi = new HIQAPI.FleetContactRequestsApi(globalConf);
const Stores: HIQAPI.StoresApi = new HIQAPI.StoresApi(globalConf);
const TyreBrands: HIQAPI.TyreBrandsApi = new HIQAPI.TyreBrandsApi(globalConf);
const TyreCategories: HIQAPI.TyreCategoriesApi = new HIQAPI.TyreCategoriesApi(globalConf);
const Tyres: HIQAPI.TyresApi = new HIQAPI.TyresApi(globalConf);
const TyreTreadPatterns: HIQAPI.TyreTreadPatternsApi = new HIQAPI.TyreTreadPatternsApi(globalConf);
const VehicleManufacturers: HIQAPI.VehicleManufacturersApi = new HIQAPI.VehicleManufacturersApi(globalConf);
const VehicleModels: HIQAPI.VehicleModelsApi = new HIQAPI.VehicleModelsApi(globalConf);
const VehicleModelYears: HIQAPI.VehicleModelYearsApi = new HIQAPI.VehicleModelYearsApi(globalConf);
const VehicleSpecifications: HIQAPI.VehicleSpecificationsApi = new HIQAPI.VehicleSpecificationsApi(globalConf);
const CampaignSubmissions: HIQAPI.CampaignSubmissionsApi = new HIQAPI.CampaignSubmissionsApi(globalConf);

export { 
  ContactRequests,
  QuoteRequests,
  FranchiseRequests,
  FleetContactRequests,
  Stores,
  TyreBrands,
  TyreCategories,
  Tyres,
  TyreTreadPatterns,
  VehicleManufacturers,
  VehicleModels,
  VehicleModelYears,
  VehicleSpecifications,
  CampaignSubmissions
};