import { EpPropMergeType } from "element-plus/es/utils";
import { useGtm, TrackEventOptions } from '@gtm-support/vue-gtm';
import { ProblemDetails, TyreListViewModel, TimeSpan, StoreListViewModel } from "@/api-client";
import { ElMessage } from "element-plus";
import { useMiscStore } from '@/stores/misc';
import { ValidateFieldsError } from "async-validator";

export function getDeepCopy(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

export function isQTEnabled() {
  return process.env.VUE_APP_QUICKTYRE_STORE_ENABLED == 'true';
}

export function getQTLink() {
  return process.env.VUE_APP_QUICKTYRE_LINK;
}

export function getQTMedia() {
  return process.env.VUE_APP_QUICKTYRE_MEDIA;
}

export function formatPurchaseUrlURL(item: TyreListViewModel) {
  if (!item.purchaseUrl) {
    return '';
  }
  if (item.purchaseUrl.includes("http")) {
    return item.purchaseUrl;
  }
  else {
    const url = item.purchaseUrl.includes("tyre/") ? "" : "/tyre" + item.purchaseUrl;
    return getQTLink() + url;
  }
}

export function getFallbackTyreImg() {
  return `${getQTLink()}/content/thumbs/Imagecomingsoon2.jpg`;
}

export function thumbnailImageError(event: any/* Event */) {
  event.target!.dataset.srcError = event.target!.src;
  event.target!.src = getFallbackTyreImg();
}

export function formatVideoMedia(video: string) {
  if (video.toLowerCase().search('youtu') > -1) {
    if (video.toLowerCase().search('youtube') > -1) {
      video = video.replace('watch?v=', 'embed/').split('&amp')[0];
    }
    else if (video.toLowerCase().search('youtu.be') > -1) {
      video = video.replace('youtu.be/', 'youtube.com/embed/').split('&amp')[0];
    }
    video = video + "?rel=0";
  }

  
  return video;
}

export function scrollTop(toScrollSelector = "", scrollIfAbove = true, offset?: number) {
  if (toScrollSelector) {
    const destination = document.querySelector(toScrollSelector) as HTMLElement;
    if (destination) {
      const navBar = document.querySelector("#navbar") as HTMLElement;
      const scrollOffset = destination.offsetTop - (navBar ? navBar.offsetHeight : 0);

      if (scrollOffset > window.scrollY && !scrollIfAbove) {
        return;
      }

      // setTimeout used to send to end of stack
      setTimeout(() => {
        window.scrollTo(0, scrollOffset + (offset || 0));
      }, 0);
    }
  } else {
    window.scrollTo(0, 0);
  }

  return;
}

export function CmsBaseUrl () {
  return process.env.VUE_APP_APOLLO_ENGINE_SERVICE
}

export function MarkdownMediaSrcReplace (string: string) {
  return string.replaceAll('src="/media/', `class="img-fluid" src="${process.env.VUE_APP_APOLLO_ENGINE_SERVICE}/media/`)
}

function getDigit(input: string) {
  return input.match(/\d+/)![0]
}

export function getSizeFromString (size: string) {
  let sizeObj = {
    sectionWidth: "0",
    aspectRatio: "0",
    rimSize: "0"
  }
  if (size.includes("/")) {
    let split = size.split("/");
    sizeObj.sectionWidth = getDigit(split[0]);

    split = split[1].split("R")
    sizeObj.aspectRatio = getDigit(split[0]);
    sizeObj.rimSize = getDigit(split[1]);
  }
  else {
    let split = size.split("R")
    sizeObj.sectionWidth = getDigit(split[0]);
    sizeObj.aspectRatio = "80";
    sizeObj.rimSize = getDigit(split[1]);
  }

  return sizeObj;
}

export function triggerGtmEvent (event: TrackEventOptions) {
  // TrackEventOptions can consist of...
  // <event>    string    Name of event.
  // <action>   string    The value that will appear as the event action in Google Analytics Event reports.
  // <label>    string    The label of the event.
  // <value>    number    A non-negative integer that will appear as the vent value.
  const gtm = useGtm();
  gtm?.trackEvent(event);
}

export function gtmDatalayerEvent (eventTitle: string, eventObj: Object | null = null, objectName: string = "body") {
  let event = {
    event: eventTitle
  }
  if (eventObj) {
    Object.assign(event, { [objectName]: eventObj });
  }

  if (process.env.VUE_APP_ENABLE_GTM === 'true') {
    window.dataLayer?.push(event);
  }
  else {
    console.info("%cGTM:", "color: white; background-color: teal; font-weight: 800; font-size: 15px; padding: 2px 3px;", event, "(*pushed when on production)");
  }
}

export interface TyreSizeObj {
  sectionWidth: string,
  aspectRatio: string,
  rimSize: string
}

export interface TyreSizeQuery {
  isImperial?: string
}

export interface TyreGtmEventObj {
  section_width?: string,
  aspect_ratio?: string,
  rim_size?: string,
  manufacturer?: string,
  model?: string,
  year?: string,
  vehicle_specification?: string,
  tyre_size?: string
}

export interface TyreSearchObj {
  params: TyreSizeObj,
  query?: TyreSizeQuery,
  search: TyreGtmEventObj
}

export interface GoogleAnalyticsEcommerceObj {
  currency: string,
  item_list_name?: string,
  items: Array<GoogleAnalyticsEcommerceItem>
}

interface GoogleAnalyticsEcommerceItem {
  sku: string,
  item_name: string,
  item_brand: string,
  price: number,
  index: number
}

export function uploadPath () {
  return `${process.env.VUE_APP_ROOT_API}/files`;
}

export function hasDateTimePassed(targetDateTime: string | undefined) {
  if (targetDateTime) {
    // Parse the target date and time string into a Date object
    const targetDate = new Date(targetDateTime);

    // Get the current date and time
    const currentDate = new Date();

    // Compare the target date and time with the current date and time
    return targetDate < currentDate;
  }
  return false;
}

export function isTodayInRange(startDate: string, endDate: string) {
  // Parse the input dates
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  // Get the current date
  const currentDate = new Date();

  // Adjust the end date to include the full day
  endDateObj.setHours(23, 59, 59, 999);

  // Check if the current date is greater than or equal to the start date
  // and less than or equal to the end date
  return currentDate >= startDateObj && currentDate <= endDateObj;
}

export function getDateDifference(date: string | undefined) {
  if (date) {
    // Parse the input date
    const inputDate = new Date(date) as any;

    // Get the current date
    const currentDate = new Date() as any;

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - inputDate;

    // Calculate the number of milliseconds in a day, month, and year
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const millisecondsPerMonth = 30.44 * millisecondsPerDay; // Approximate value
    const millisecondsPerYear = 365.25 * millisecondsPerDay; // Approximate value

    // Calculate the difference in days, months, and years
    const daysDifference = Math.floor(timeDifference / millisecondsPerDay);
    const monthsDifference = Math.floor(timeDifference / millisecondsPerMonth);
    const yearsDifference = Math.floor(timeDifference / millisecondsPerYear);

    // Determine whether to use "day(s)," "month(s)," or "year(s)"
    let unit = "day";
    let hasPassed = false;
    let difference = daysDifference;

    if(hasDateTimePassed(date)) {
      hasPassed = true;
    }

    if ((hasPassed && yearsDifference >= 1) || (!hasPassed && Math.abs(yearsDifference + 1) >= 1)) {
      unit = "year";
      difference = !hasPassed ? Math.abs(yearsDifference + 1) : yearsDifference;
    } else if ((hasPassed && monthsDifference >= 1) || (!hasPassed && Math.abs(monthsDifference + 1) >= 1)) {
      unit = "month";
      difference = !hasPassed ? Math.abs(monthsDifference + 1) : monthsDifference;
    }

    if(!daysDifference && !monthsDifference && !yearsDifference) {
      return "today"
    } else if(hasPassed) {
      return `${Math.abs(difference)} ${unit}${Math.abs(difference) !== 1 ? "s" : ""} ago`;
    } else {
      return `${Math.abs(difference)} ${unit}${Math.abs(difference) !== 1 ? "s" : ""}`;
    }
    
  }
  return "";
}

export function addDays(date: Date, days: number) {
  const dateCopy = new Date(date);
  dateCopy.setDate(date.getDate() + days);
  return dateCopy;
}

export function formatDate(date: string, separator = "/") {
  if (date) {
    const dateObject = new Date(date);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    return `${appendLeadingZeroes(day)}${separator}${appendLeadingZeroes(month)}${separator}${year}`;
  }
  return "";
}

export function formatTime(date: string) {
  if (date) {
    const dateObject = new Date(date);
    const hours: string | number = appendLeadingZeroes(dateObject.getHours());
    const minutes: string | number = appendLeadingZeroes(dateObject.getMinutes());
    return `${hours}:${minutes}`;
  }
  return "";
}

export function formatTimeSpan(date: TimeSpan) {
  if (date) {
    return date.toString().replace(":00:00", ":00");
  }
  return "";
}

export function formatTimeZone(input: Date | string) {
  if (input) {
    let date = input;
    if (typeof(date) === 'string') {
      date = new Date(input);
    }
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
  }
  return "";
}

export function thousandSeparator(num: number) {
  let parts = num.toFixed(2).toString().split(".");
    
  // Add commas to separate thousands
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  
  // Join integer and decimal parts with a dot
  return parts.join(".");
}

export function toKebabCase (input: string) {
  return input.replace(/[\s_]+/g, '-').toLowerCase();
}

function appendLeadingZeroes(n: number) {
  if (n <= 9) {
    return "0" + n;
  }
  return n;
}

export function checkStore(state: any, stateItem: string, storeName: string, getterName: string) {
  if (state && state[stateItem] != null) {
    return state[stateItem];
  } else {
    const pinia = localStorage.getItem("pinia");
    const store = JSON.parse(pinia!);
    if (store == null) {
      return null;
    }
    if (store[storeName] == null) {
      return null;
    }
    const storageItem = store[storeName][getterName];
    if (storageItem != null) {
      return storageItem;
    }

    return null;
  }
}

export function coordinateVerification(store: StoreListViewModel) {
  const isInvalid = (store.latitude == undefined && store.longitude == undefined) ||
    (store.latitude == null && store.longitude == null) ||
    (store.latitude == 0 && store.longitude == 0);
  return !isInvalid;
}

export function statusFilter(status: any) {
  const statusMap: { [key: string]: string } = {
    false: "success",
    true: "danger",
  };
  return statusMap[status] as EpPropMergeType<StringConstructor, "" | "success" | "warning" | "info" | "danger", unknown> | undefined;
}

export function handleError(error: ProblemDetails | any, displayMessage = true) {
  console.log(error);

  const miscStore = useMiscStore();
  const defaultError = "<p>An unexpected error has occurred.</p>";
  let errorMessage = defaultError;

  setTimeout(() => {
    miscStore.isLoading = false;
    miscStore.isLoadingOverride = false;
  }, 500);

  if(displayMessage) {
    if (error && Object.prototype.hasOwnProperty.call(error, "response")) {
      errorMessage =
      `<p>${(error.response?.data as ProblemDetails).title || (error.response?.data as ProblemDetails).detail  || defaultError}</p>`;

      if(error?.response?.data?.errors) {
        const errorMessages = error.response.data.errors;
        if(Object.keys(errorMessages).length) {
          let arr: string[] = []
          Object.keys(errorMessages).forEach((key:any) => {
            arr.push(`<p class='m-0'>${errorMessages[key]}</p>`);
          })
          errorMessage += arr.join("")
        }
      }
    } else if (error && Object.prototype.hasOwnProperty.call(error, "errors")) {
      const errorMessages = error.errors;
      if(errorMessages.length) {
        let arr: string[] = []
        Object.values(errorMessages).forEach((err:any) => {
          arr.push(`<p class='m-0'>${err.friendlyMessage}</p>`);
        })
        errorMessage += arr.join("")
      }
    } else if (error) {
      errorMessage = error.message || defaultError;
    }

    ElMessage.error({ message: errorMessage, showClose: true, duration: 0, grouping: true, dangerouslyUseHTMLString: true });
  }
}

export function handleInvalidForm(fields: ValidateFieldsError) {
  if (fields) {
    const field = Object.values(fields)[0];
    if (field[0]?.field) {
      document.getElementsByName(field[0].field)[0]?.focus();
    }
  }
  console.log("invalid");
}

export const disabledDate = (time: Date) => {
  const today = new Date();
  let minDate = today.setDate(today.getDate());

  return time.getTime() > minDate;
}

export function tableResizeObserver(_this:any) {
  // This is a hack to remove the "resizeobserver loop limit exceeded" error overlay that appears on screen resize/manipulation
  // Hack source: https://juejin.cn/post/7234703748403839036

  const debounce = (fn: ResizeObserverCallback, delay: number, self: any) => {
    let timer: any = null;
    return function () {
      let context = self;
      let args = arguments as any; //[entries: ResizeObserverEntry[], observer: ResizeObserver]
      clearTimeout(timer);
      timer = setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    };
  };
  const _ResizeObserver = window.ResizeObserver;
  const self = _this;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback: ResizeObserverCallback) {
      callback = debounce(callback, 16, self);
      super(callback);
    }
  };
}