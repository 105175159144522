import gql from 'graphql-tag'

const image = gql`query GetImage($alias: String!) {
  image(where: {alias: {alias: $alias}}) {
    displayText
    image {
      urls(first: 1)
    }
    altText
  }
}`

interface imageObj {
  displayText: string,
  image: string,
  altText: string
}

export {
  image,
  imageObj
}