import { GetTyresQueryOrderByField, OrderByDirection, PagedResultOfString, PagedResultOfTyreBrandListViewModel, PagedResultOfTyreCategoryListViewModel, PagedResultOfTyreListViewModel, TyreListViewModel, TyreTreadPatternViewModel, TyreBrandListViewModel } from "@/api-client";
import { TyreBrands, TyreCategories, TyreTreadPatterns, Tyres } from "@/network/api";
import { handleError } from "@/utilities";

export type TyreRangeFilterValues = {
  orderByField: string,
  isRunFlat: boolean,
  tyreBrands: number[],
  tyreTypes: string[],
  tyrePrice: [number, number]
}

// Tyres
export function getTyres(
  pageNumber: number = 1,
  pageSize: number = 9,
  sectionWidth?: string | undefined,
  aspectRatio?: string | undefined,
  rimSize?: string | undefined,
  treadPatternId?: number | undefined,
  orderByDirection?: OrderByDirection | undefined,
  orderByField?: GetTyresQueryOrderByField | undefined,
  minimumPrice?: number | undefined,
  maximumPrice?: number | undefined,
  isRunFlat?: boolean | undefined,
  tyreBrandIds?: number[] | undefined,
  tyreCategoryIds?: number[] | undefined
): Promise<PagedResultOfTyreListViewModel> {
  return new Promise((resolve, reject) => {
    Tyres.apiTyreGet(
      pageNumber,
      pageSize,
      orderByDirection,
      orderByField,
      minimumPrice,
      maximumPrice,
      isRunFlat,
      tyreBrandIds,
      tyreCategoryIds,
      sectionWidth,
      aspectRatio,
      rimSize,
      treadPatternId
    )
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data);
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function getTyreSectionWidths(pageNumber: number = 1, pageSize: number = 99999, isImperial: boolean = false): Promise<PagedResultOfString> {
  return new Promise((resolve, reject) => {
    Tyres.apiTyresSectionwidthsGet(pageNumber, pageSize, isImperial)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        // TODO: stop sorting in TS- section width by number
        res.data.data = res.data.data.sort((a: string, b: string) => +a - +b);
        resolve(res.data);
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function getTyreAspectRatios(pageNumber: number = 1, pageSize: number = 99999, sectionWidth: string): Promise<PagedResultOfString> {
  return new Promise((resolve, reject) => {
    Tyres.apiTyresAspectratiosSectionWidthGet(sectionWidth, pageNumber, pageSize)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        // TODO: stop sorting in TS- aspect ratio by number
        res.data.data = res.data.data.sort((a: string, b: string) => +a - +b);
        resolve(res.data);
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function getTyreRimSizes(pageNumber: number = 1, pageSize: number = 99999, aspectRatio: string, sectionWidth: string | undefined = undefined): Promise<PagedResultOfString> {
  return new Promise((resolve, reject) => {
    Tyres.apiTyresRimsizesAspectRatioGet(aspectRatio, pageNumber, pageSize, sectionWidth)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        // TODO: stop sorting in TS- rim size by number
        res.data.data = res.data.data.sort((a: string, b: string) => +a - +b);
        resolve(res.data);
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function getTyreSizes(pageNumber: number = 1, pageSize: number = 99999, searchTerm?: string | undefined): Promise<PagedResultOfString> {
  return new Promise((resolve, reject) => {
    Tyres.apiTyresTyresizesGet(pageNumber, pageSize, searchTerm)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data);
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

// TyreTreadPatterns
export function getTyreTreadPatternBySlug(slug: string): Promise<TyreTreadPatternViewModel> {
  return new Promise((resolve, reject) => {
    TyreTreadPatterns.apiTyreTreadPatternSlugGet(slug)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data);
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

// TyreCategories
export function getTyreCatergories(pageNumber: number = 1, pageSize: number = 99999): Promise<PagedResultOfTyreCategoryListViewModel> {
  return new Promise((resolve, reject) => {
    TyreCategories.apiTyreCategoryGet(pageNumber, pageSize)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data);
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

// TyreBrands
export function getTyreBrands(pageNumber: number = 1, pageSize: number = 99999): Promise<PagedResultOfTyreBrandListViewModel> {
  return new Promise((resolve, reject) => {
    TyreBrands.apiTyreBrandGet(pageNumber, pageSize)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data);
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}