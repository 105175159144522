import gql from 'graphql-tag'

const articleCategory = gql`query GetArticleCategory {
  taxonomy(where: {alias: {alias: "article-category"}}) {
    taxonomy {
      contentItems {
        displayText
        contentItemId
        ... on ArticleCategory {
          alias {
            alias
          }
        }
      }
    }
  }
}`

const articleList = gql`query GetArticleList {
  article {
    displayText
    blurb
    thumbnailImage {
      urls(first: 1)
    }
    alias {
      alias
    }
    category {
      termContentItemIds
      termContentItems {
        ... on ArticleCategory {
          alias {
            alias
          }
        }
      }
    }
  }
}`

const article = gql`query GetArticle($alias: String!) {
  article(where: {alias: {alias: $alias}}) {
    displayText
    bannerImage {
      urls(first: 1)
    }
    bodyCopy {
      contentItems {
        ... on ContentBlock {
          displayText
          markdownBody {
            html
          }
          contentBlockSubtitle
          contentBlockTitle
          additionalContent {
            contentItems {
              ... on Image {
                image {
                  urls
                }
              }
              ... on ContentBlockFlowpart {
                displayText
                contentBlockSubtitle
                markdownBody {
                  html
                }
                image {
                  urls
                }
                imagePosition
              }
            }
          }
        }
      }
    }
  }
}`

interface articleObj {
  alias: any,
  displayText: string,
  blurb: string,
  thumbnailImage: string,
  bannerImage: string,
  bodyCopy: any,
  category?: {
    termContentItemIds: Array<string>,
    termContentItems?: {
      alias?: {
        alias: string
      }
    }
  }
}

export {
  articleCategory,
  articleList,
  article,
  articleObj
}