import gql from 'graphql-tag'

const testimonial = gql`query GetTestimonial {
  testimonial {
    displayText
    jobTitle
    copy {
      html
    }
    rating
  }
}`

interface testimonialObj {
  displayText: string,
  jobTitle: string,
  rating: number,
  copy: {
    html: String
  }
}

export {
  testimonial,
  testimonialObj
}