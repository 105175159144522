import gql from 'graphql-tag'

const seo = gql`query GetSeoMetadata($url: String!) {
  sEO(where: {path: $url}) {
    seoMeta {
      pageTitle
      metaDescription
      canonical
      metaKeywords
      googleSchema
      metaRobots
      twitterImage {
        urls
      }
      openGraphImage {
        urls
      }
      defaultSocialImage {
        urls
      }
    }
  }
}`

interface seoObj {
  pageTitle: string,
  metaDescription: string,
  canonical: string,
  metaKeywords: string,
  googleSchema: string,
  metaRobots: string,
  twitterImage: {
    urls: Array<string>
  },
  openGraphImage: {
    urls: Array<string>
  },
  defaultSocialImage: {
    urls: Array<string>
  }
}

export {
  seo,
  seoObj
}