import { getVehicleManufacturers, getVehicleModelYears, getVehicleModels, getVehicleSpecifications } from "@/api-calls/vehicles";
import { getTyreAspectRatios, getTyreRimSizes, getTyreSectionWidths } from "@/api-calls/tyres";

export async function listAspectRatio(sectionWidth: string = "") {
    if(sectionWidth) {
        return (await getTyreAspectRatios(1, 99999, sectionWidth)).data;
    } else {
        return [];
    } 
}

export async function listRimSize(aspectRatio: string = "", sectionWidth?: string | undefined) {
    if(aspectRatio) {
        return (await getTyreRimSizes(1, 99999, aspectRatio, sectionWidth)).data;
    } else {
        return [];
    }
}

export async function listModels(manufacturer: string = "") {
    if(manufacturer) {
        return await getVehicleModels(manufacturer);
    } else {
        return [];
    } 
}

export async function listModelYear(manufacturer: string = "", model: string = "") {
    if(model) {
        return await getVehicleModelYears(manufacturer, model);
    } else {
        return [];
    }
}

export async function listSpecification(manufacturer: string = "", model: string = "", modelYear: string = "") {
    if(modelYear) {
        return await getVehicleSpecifications(manufacturer, model, modelYear);
    } else {
        return [];
    }
}