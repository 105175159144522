import { PagedResultOfStoreListViewModel, PagedResultOfString } from "@/api-client";
import { Stores } from "@/network/api";
import { handleError } from "@/utilities";

// Stores
export function getStoresCities(pageNumber: number = 1, pageSize: number = 99999, region : string): Promise<PagedResultOfString> {
  return new Promise((resolve, reject) => {
    Stores.apiStoresCitiesGet(pageNumber, pageSize, region)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        // TODO: stop sorting cities by name in TS
        res.data.data = res.data.data.sort((a: string, b: string) => a.localeCompare(b));
        resolve(res.data);
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function getStoresRegions(pageNumber: number = 1, pageSize: number = 99999): Promise<PagedResultOfString> {
  return new Promise((resolve, reject) => {
    Stores.apiStoresRegionsGet(pageNumber, pageSize)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        // TODO: stop sorting regions by name in TS
        res.data.data = res.data.data.sort((a: string, b: string) => a.localeCompare(b));
        resolve(res.data);
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function getStores(pageNumber: number = 1, pageSize: number = 99999, tradingName: string | undefined, regionName: string, city: string | undefined = undefined): Promise<PagedResultOfStoreListViewModel> {
  return new Promise((resolve, reject) => {
    Stores.apiStoreGet(pageNumber, pageSize, tradingName, regionName, city)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data);
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

export function getStoresNearMe(pageNumber: number = 1, pageSize: number = 99999, latitude: number = -29.85848736558187, longitude: number = 31.030541625019545): Promise<PagedResultOfStoreListViewModel> {
  return new Promise((resolve, reject) => {
    Stores.apiStoresNearMeGet(pageNumber, pageSize, longitude, latitude)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        resolve(res.data);
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

type DdlItem = {
  id: string,
  name: string
}

export const provincesDdl: DdlItem[] = [
  {
    id: "1",
    name: "Eastern Cape",
  },
  {
    id: "2",
    name: "Free State",
  },
  {
    id: "3",
    name: "Gauteng",
  },
  {
    id: "4",
    name: "KwaZulu-Natal",
  },
  {
    id: "5",
    name: "Limpopo",
  },
  {
    id: "6",
    name: "Mpumalanga",
  },
  {
    id: "7",
    name: "Northern Cape",
  },
  {
    id: "8",
    name: "North West",
  }
]