import { CreateContactRequestCommand, CreateQuoteRequestCommand, CreateFranchiseApplicationCommand, CreateFleetContactRequestCommand, JsonResponseOfGuid, CreateCampaignSubmissionCommand } from "@/api-client";
import { ContactRequests, QuoteRequests, FranchiseRequests, FleetContactRequests, CampaignSubmissions } from "@/network/api";
import { handleError, scrollTop } from "@/utilities";
import { ElMessage } from "element-plus";
import { gtmDatalayerEvent } from "@/utilities";

// ContactRequests
export function postContactRequest(item: CreateContactRequestCommand): Promise<JsonResponseOfGuid> {
  return new Promise((resolve, reject) => {
    ContactRequests.apiContactRequestPost(item)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        scrollTop()
        ElMessage.success({ message: "Request submitted!", showClose: true, grouping: true });

        const event = {
          name: item.firstName,
          surname: item.lastName,
          email: item.email,
        };
        gtmDatalayerEvent("contact_us", event);

        resolve(res.data);
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

// QuoteRequests
export function postQuoteRequest(item: CreateQuoteRequestCommand): Promise<JsonResponseOfGuid> {
  return new Promise((resolve, reject) => {
    QuoteRequests.apiQuoteRequestPost(item)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        scrollTop()
        ElMessage.success({ message: "Request submitted!", showClose: true, grouping: true });

        const event = {
          name: item.firstName,
          surname: item.lastName,
          email: item.email,
          tel: item.phoneNumber,
          province: item.province,
          city: item.city,
          store: item.store,
          vehicleManufacturer: item.vehicleManufacturer,
          vehicleModel: item.vehicleModel,
          vehicleYear: item.vehicleYear,
          quoteRequestProducts: item.quoteRequestProducts,
          quoteRequestServices: item.quoteRequestServices
        };
        gtmDatalayerEvent("quote_request_submitted", event);

        resolve(res.data);
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

// FranchiseRequest
export function postFranchiseForm(item: CreateFranchiseApplicationCommand): Promise<JsonResponseOfGuid> {
  return new Promise((resolve, reject) => {
    FranchiseRequests.apiFranchiseApplicationPost(item)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        scrollTop()
        ElMessage.success({ message: "Request submitted!", showClose: true, grouping: true });

        const event = {
          name: item.firstName,
          surname: item.lastName,
          email: item.email,
        };
        gtmDatalayerEvent("franchise_form_submit", event);

        resolve(res.data);
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

// FleetAccount
export function postFleetAccountRequest(item: CreateFleetContactRequestCommand): Promise<JsonResponseOfGuid> {
  return new Promise((resolve, reject) => {
    FleetContactRequests.apiFleetContactRequestPost(item)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        scrollTop()
        ElMessage.success({ message: "Request submitted!", showClose: true, grouping: true });

        const event = {
          companyName: item.companyName,
          name: item.firstName,
          surname: item.lastName,
          email: item.email,
          tel: item.phoneNumber,
          industry: item.industry,
          numberVehiclesInFleet: item.numberVehiclesInFleet,
          typesOfVehicles: item.typesOfVehicles,
          geographicCoverage: item.geographicCoverage
        };
        gtmDatalayerEvent("fleet_account_submitted", event);

        resolve(res.data);
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}

// CampaignCapture
export function postCampaignCapture(item: CreateCampaignSubmissionCommand): Promise<JsonResponseOfGuid> {
  return new Promise((resolve, reject) => {
    CampaignSubmissions.apiCampaignSubmissionPost(item)
    .then((res) => {
      if ([200, 201, 204].includes(res.status)) {
        scrollTop()
        ElMessage.success({ message: "Request submitted!", showClose: true, grouping: true });

        const event = {
          campaignTitle: item.campaignTitle,
          province: item.province,
          city: item.city,
          store: item.store,
          selloutDate: item.selloutDate,
          claimedDate: item.claimedDate,
          tyreSize: item.tyreSize,
          numberOfTyres: item.numberOfTyres,
          amount: item.amount || "n/a"
        };
        gtmDatalayerEvent("campaign_capture_submitted", event);

        resolve(res.data);
      }
    })
    .catch((error) => {
      handleError(error);
      reject(error);
    });
  });
}