import gql from 'graphql-tag'

const contentBlock = gql`query GetContentBlock($alias: String!) {
  contentBlock(where: {alias: {alias: $alias}}) {
    contentBlockTitle
    contentBlockSubtitle
    markdownBody {
      html
    }
    additionalContent {
      contentItems {
        ... on Buttons {
          buttonList {
            contentItems {
              ... on LinkMenuItem {
                linkMenuItem {
                  name
                  url
                }
              }
            }
          }
        }
        ... on Image {
          image {
            urls(first: 1)
          }
          altText
        }
        ... on ContentBlockFlowpart {
          displayText
          contentBlockSubtitle
          markdownBody {
            html
          }
          image {
            urls(first: 1)
          }
          imagePosition
        }
      }
    }
  }
}`

interface contentBlockObj {
  contentBlockTitle?: String,
  contentBlockSubtitle?: String,
  markdownBody?: {
    html: String
  },
  additionalContent?: {
    contentItems: [ 
      {
        buttonList: {
          contentItems: [
            {
              linkMenuItem: {
                name: String,
                url: String
              }
            }
          ]
        }
      },
      {
        images: {
          urls: [ "" ]
        }
      }
    ]
  }
}

const clientQuotes = gql`query GetClientQuotes {
  contentBlock(where: {alias: {alias: "home-client-quotes"}}) {
    contentBlockTitle
    additionalContent {
      contentItems {
        ... on ContentBlockFlowpart {
          displayText
          contentBlockSubtitle
          markdownBody {
            html
          }
        }
      }
    }
  }
}`

export {
  contentBlock,
  contentBlockObj,
  clientQuotes
}