import gql from 'graphql-tag'

const banner = gql`query GetBanner($alias: String!) {
  banner(where: {alias: {alias: $alias}}) {
    displayText
    bannerMainText {
      html
    }
    bannerSubtext {
      html
    }
    image {
      urls(first: 1)
    }
    mobileImage {
      urls(first: 1)
    }
    bannerImageAlignment
    bannerImageAltText
  }
}`

interface bannerObj {
  displayText: string,
  bannerMainText: string,
  bannerSubtext: string,
  image: string,
  mobileImage: string,
  bannerImageAlignment: string,
  bannerImageAltText: string
}

export {
  banner,
  bannerObj
}